import { action, computed, thunk } from "easy-peasy";
import { easyStateSetters } from "lib/easyState";
import {
  assign,
  pick,
  findIndex,
  last,
  first,
  without,
  filter,
  concat,
  uniq,
  uniqBy,
  chunk
} from "lodash";
import {
  fetchTrips as apiFetchTrips,
  fetchSearchOptions as apiFetchSearchOptions,
  fetchTripMap,
  fetchTrip,
  simulateTracking,
  toggleTracking as apiToggleTracking
} from "services/apiTrips";
import { fetchVendorsList } from "services/apiVendors";
import { updateTripPosition } from "components/admin_v2/trips/mapboxApi";
import { setFlashMessage } from "services";
import { reorderTrips } from "../tripHelpers";
import { pastAndNotToday, toDate } from "lib/dates";
import I18n from "utils/i18n.js";
import store from "context/admin_v2/store";

export const BOARD_STATES = { collapsed: "collapsed", half: "half", full: "full" };

export const TRIP_FILTERS = [
  "vehicleType",
  "timeZone",
  "status",
  "tripType",
  "routeType",
  "onlyActive",
  "vendorId",
  "addressId",
  "studentId",
  "routeId",
  "schoolIds"
];
const NON_NULLABLE_FILTERS = ["routeType", "tripType", "schoolIds"];
const RESET_PROPS = [
  "activeTrips",
  "selectedTrip",
  "addressId",
  "studentId",
  "routeId",
  "trips",
  "vendorOptions",
  "searchOptions",
  "counters",
  "tripInfo"
];

const DEFAULT_TRACK_FORM = { delay: "-3" };

export const defaultState = {
  // status
  refresh: false,
  loading: false,
  optionsLoading: false,
  // settings
  showCompletedStops: true,
  showWaypoints: false,
  collapseAllStudents: false,
  boardState: BOARD_STATES.collapsed,
  // filters
  timeZone: null,
  vehicleType: null,
  routeType: "all",
  tripType: "to_school",
  onlyActive: null,
  status: null,
  vendorId: null,
  addressId: null,
  studentId: null,
  routeId: null,
  schoolIds: [],
  // data
  activeTrips: [],
  selectedTrip: null,
  tripInfo: null,
  trips: [],
  vendorOptions: [],
  counters: [],
  searchOptions: [],
  map: null,
  lastUpdate: new Date(),
  address: null,
  student: null,
  route: null,
  // Trip edit data
  editTrip: null,
  trackingForm: DEFAULT_TRACK_FORM,
  loadError: false
};

export const tripsStore = (initialData = {}) => ({
  ...easyStateSetters(defaultState, initialData),

  searchVal: computed((state) => {
    if (state.searchOptions.length === 0) {
      if (state.routeId) {
        return state.route;
      } else if (state.studentId) {
        return state.student;
      } else if (state.addressId) {
        return state.address;
      } else {
        return null;
      }
    }

    if (state.routeId) {
      return state.searchOptions.find(
        (so) => so.type === "Route" && so.id.toString() === state.routeId
      );
    } else if (state.studentId) {
      return state.searchOptions.find(
        (so) => so.type === "Student" && so.id.toString() === state.studentId
      );
    } else if (state.addressId) {
      return state.searchOptions.find(
        (so) => so.type === "Address" && so.id.toString() === state.addressId
      );
    } else {
      return null;
    }
  }),

  statusesMapping: computed((state) => {
    return state.counters.map((c) => ({
      id: c.name,
      name: `${I18n.t(`trip.counters.${c.name}`)} (${c.count})`
    }));
  }),

  resetSelections: action((state) => {
    const defaultProps = pick(defaultState, ["activeTrips", "selectedTrip"]);
    assign(state, defaultProps);
  }),

  reset: action((state) => {
    const defaultProps = pick(defaultState, RESET_PROPS);
    assign(state, defaultProps);
  }),

  fetchSearchOptions: thunk(async (actions, _, h) => {
    const tripsIds = h.getState().tripsIds;
    apiFetchSearchOptions({ tripsIds })
      .then((resp) => {
        actions.setSearchOptions(resp);
      })
      .catch((err) => {
        setFlashMessage(err.message);
      })
      .finally(() => actions.setOptionsLoading(false));
  }),

  fetchTripPopupInfo: thunk(async (actions, tripId, _h) => {
    fetchTrip(tripId)
      .then((resp) => {
        actions.setTripInfo(resp);
      })
      .catch((err) => {
        setFlashMessage(err.message);
      });
  }),

  setFromRouter: action((state, props) => {
    let searchProps = pick(props, TRIP_FILTERS);

    for (const k of without(TRIP_FILTERS, ...NON_NULLABLE_FILTERS)) {
      if (!searchProps[k]) {
        searchProps[k] = null;
      }
    }

    if (searchProps.schoolIds) {
      searchProps.schoolIds = searchProps.schoolIds.split("_").map((i) => parseInt(i, 10));
    }
    assign(state, searchProps);
  }),

  schoolIdsQueryParam: thunk((actions, ids, _h) => {
    if (ids.length === 0) {
      return null;
    }
    return ids
      .map((id) => parseInt(id, 10))
      .sort()
      .join("_");
  }),

  updateEditTrip: action((state, trip) => {
    state.editTrip = trip;
  }),

  fetchTrips: thunk(async (actions, params, h) => {
    actions.setLoading(true);
    const state = h.getState();

    const stateParams = pick(state, TRIP_FILTERS);
    apiFetchTrips({ ...params, ...stateParams })
      .then((r) => {
        actions.setData(r);
        // add currently happening trips to active if search filter is selected
        if (state.routeId || state.studentId || state.addressId) {
          actions.addTripsToActive(filter(r.trips, { active: true }));
        }
      })
      .finally(() => actions.setLoading(false));
  }),

  fetchIndividualTrips: thunk(async (actions, params, h) => {
    actions.setLoading(true);
    const state = h.getState();

    const stateParams = pick(state, TRIP_FILTERS);
    const schoolIdsChunks = chunk(state.schoolIds, 5);

    const allTrips = await Promise.all(
      schoolIdsChunks.map(async (idsChunk) => {
        return apiFetchTrips({ ...params, ...stateParams, schoolIds: idsChunk });
      })
    );
    const extractCounters = () => {
      const allCounters = allTrips.map((trip) => trip.counters).flat();
      const names = uniq(allCounters.map((counter) => counter.name));

      return names.map((name) => {
        return {
          name: name,
          count: allCounters
            .filter((counter) => counter.name === name)
            .reduce((acc, counter) => acc + counter.count, 0)
        };
      });
    };
    const combinedTrips = {
      trips: uniqBy(allTrips.map((trip) => trip.trips).flat(), "id"),
      counters: extractCounters()
    };

    actions.setData(combinedTrips);
    // add currently happening trips to active if search filter is selected
    if (state.routeId || state.studentId || state.addressId) {
      actions.addTripsToActive(filter(combinedTrips.trips, { active: true }));
    }
    actions.setLoading(false);
  }),

  refetchTrips: thunk(async (actions, params, h) => {
    const state = h.getState();
    const stateParams = pick(state, TRIP_FILTERS);
    apiFetchTrips({ ...params, ...stateParams })
      .then((r) => {
        // update counters & trips lists
        actions.setRefetchedData(r);
        if (!state.map) return;

        // refetch selected trip if it was changed
        if (state.selectedTrip && !r.trips.includes(state.selectedTrip.trip)) {
          actions.refetchSelectedTrip();
        }
        // update trips positions on the map
        r.trips.forEach((trip) => {
          if (trip.last_position && !trip.ended_at) {
            updateTripPosition(state.map, trip, [trip.last_position.lng, trip.last_position.lat]);
          }
        });
      })
      .catch((err) => setFlashMessage(err.message));
  }),

  fetchVendors: thunk(async (actions, params, _h) => {
    fetchVendorsList({ ...params, onlyActive: true })
      .then((r) => {
        const withUnassigned = [{ id: "unassigned", name: "Unassigned" }, ...r.vendors];
        actions.setVendorOptions(withUnassigned);
      })
      .catch((err) => setFlashMessage(err.message));
  }),

  setRefetchedData: action((state, { trips, counters }) => {
    const sortBy = store.getState().avl.sortBy;
    state.counters = counters;
    state.trips = reorderTrips(trips, sortBy);
    state.activeTrips = state.activeTrips.map((trip) => {
      return trips.find((t) => trip.id == t.id) || trip;
    });
    state.lastUpdate = new Date();
  }),

  addTripsToActive: action((state, trips) => {
    state.activeTrips = uniq(concat(state.activeTrips, trips));
  }),

  updateSelectedTrip: thunk(async (actions, trip, h) => {
    if (trip) {
      fetchTripMap(trip.id).then((resp) => {
        if (h.getState().boardState === BOARD_STATES.full) {
          actions.setBoardState(BOARD_STATES.half);
        }
        actions.setSelectedTrip(resp);
      });
    } else {
      actions.setShowWaypoints(false);
      actions.setSelectedTrip(null);
    }
  }),

  selectTripAround: thunk(async (actions, payload, h) => {
    const trips = h.getState().trips;
    const tripIdx = findIndex(trips, { id: payload.tripId });
    const next = payload.next;

    if (tripIdx < 1 && !next) {
      actions.updateSelectedTrip(last(trips));
    } else if (tripIdx === trips.length - 1 && next) {
      actions.updateSelectedTrip(first(trips));
    } else {
      const newIdx = next ? tripIdx + 1 : tripIdx - 1;
      actions.updateSelectedTrip(trips[newIdx]);
    }
  }),

  refetchSelectedTrip: thunk(async (actions, _payload, h) => {
    const tripId = h.getState().selectedTrip?.trip?.id;
    if (!tripId) return;

    fetchTripMap(tripId).then((resp) => {
      actions.setSelectedTrip(resp);
    });
  }),

  setData: action((state, data) => {
    const sortBy = store.getState().avl.sortBy;
    state.trips = reorderTrips(data.trips, sortBy);
    state.counters = data.counters;
    if (!state.trips.map((t) => t.id).includes(state.selectedTrip?.trip?.id)) {
      state.selectedTrip = null;
    }
    state.lastUpdate = new Date();
  }),

  toggleBoardState: action((state, next) => {
    if ([BOARD_STATES.collapsed, BOARD_STATES.full].includes(state.boardState)) {
      state.boardState = BOARD_STATES.half;
    } else if (next) {
      state.boardState = BOARD_STATES.full;
      state.selectedTrip = null;
    } else {
      state.boardState = BOARD_STATES.collapsed;
    }
  }),

  updateTrip: action((state, trip) => {
    const updatedTrip = trip;
    const updatedTrips = state.trips.map((trip) => {
      if (trip.id === updatedTrip.id) {
        return updatedTrip;
      }
      return trip;
    });
    state.trips = updatedTrips;
  }),

  submitTrackingForm: thunk((actions, tripId, _h) => {
    // NOTE: removed tracking form temporarily.
    // const state = h.getState();
    // actions.setSimulateTrackingDialogOpen(false);
    // actions.setTrackingForm(DEFAULT_TRACK_FORM);

    simulateTracking(tripId, DEFAULT_TRACK_FORM)
      .then((resp) => {
        actions.setEditTrip(null);
        setFlashMessage(resp.message);
      })
      .catch((err) => {
        setFlashMessage(err.message);
      });
  }),

  toggleTracking: thunk((actions, tripId, _h) => {
    apiToggleTracking(tripId)
      .then((resp) => {
        actions.updateTrip(resp.trip);
      })
      .catch((err) => {
        setFlashMessage(err.message);
      });
  }),

  copyTrackingLink: action((state, trip) => {
    navigator.clipboard.writeText(trip.tracking_url);
    setFlashMessage(`Copied to Clipboard: ${trip.tracking_url}`);
  }),

  tripsIds: computed((state) => {
    return state.trips.map((t) => t.id).sort();
  }),

  isPastTrip: computed((state) => {
    if (!state.trip) return null;

    const date = toDate(state.trip.date_iso);
    return pastAndNotToday(date);
  })
});
