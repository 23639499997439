import { computed, thunk, action } from "easy-peasy";
import { assign, get, includes, map, values, startsWith } from "lodash";
import easyState, { easyStateDate } from "../../lib/easyState";
import { setFlashMessage } from "services";
import { fetchRoute, fetchWeekStops, fetchWeekTransport } from "services/apiRoutes";
import { formatDateUrl, dateOrCurrentMonday } from "lib/dates";
import { formatISO } from "date-fns";

const routeEditorStore = {
  ...easyStateDate("date", null),
  ...easyState("route", null),
  ...easyState("direction", null),
  ...easyState("stop", null),
  ...easyState("schedule", []),
  ...easyState("daysOff", []),
  ...easyState("daysDelta", []),
  ...easyState("hideEmptyStops", true),
  ...easyState("linkDays", false),
  ...easyState("addStopDialogOpen", false),
  ...easyState("savedLocationId", null),
  ...easyState("transportData", {}),
  ...easyState("vendorScheduleDialogOpen", false),

  filteredSchedule: computed((state) => {
    return state.schedule.filter((data) =>
      data.stops.find(
        (stop) =>
          stop.students.length > 0 ||
          stop.add_events.length > 0 ||
          stop.stop?.stop_types?.indexOf("anchor") > -1 ||
          stop.counters.terminus > 0 ||
          (state.isRegular && stop.stop?.stop_types?.indexOf("school") > -1)
      )
    );
  }),

  withDaysOffOrAdjustment: computed(
    (state) => values(state.daysOff).includes(true) || values(state.daysDelta).includes(true)
  ),
  isDayOff: computed((state) => (date) => state.daysOff[date]),
  isAdjustedSchedule: computed((state) => (date) => state.daysDelta[date]),
  isDayDisabled: computed(
    (state) => (date) => state.route?.route_type !== "custom" && state.daysOff[date]
  ),
  isCrossSchool: computed((state) => state.route?.routable_type === "district"),
  isRegular: computed((state) => state.route?.route_type === "regular"),
  selectedDay: computed((state) => formatDateUrl(state.date)),
  stopTime: computed((state) => (row) => {
    const today = formatISO(new Date());
    const date = formatDateUrl(dateOrCurrentMonday(state.date));
    const selectedRow = row.stops.find((s) => s.date == date);
    return selectedRow?.stop?.raw_time || (startsWith(state.direction, "to") ? "" : today);
  }),
  sortedStops: computed((state) => ({ withFilteredSchedule = true }) => {
    const data =
      state.hideEmptyStops && withFilteredSchedule ? state.filteredSchedule : state.schedule;
    return data.sort((s1, s2) => {
      const stop1 = state.stopTime(s1);
      const stop2 = state.stopTime(s2);
      if (stop1 === stop2) return 0;
      return stop1 < stop2 ? -1 : 1;
    });
  }),
  initRoute: action((state, route) => {
    state.route = route;
    if (!includes(map(state.route.directions, "type"), state.direction)) {
      state.direction = get(state.route.directions, "[0].type");
    }
    state.schedule = [];
    state.daysOff = [];
    state.daysDelta = [];
  }),

  updateRoute: thunk(async (actions, routeId, h) => {
    const state = h.getState();

    return fetchRoute(routeId, {
      date: state.selectedDate,
      trip_types: state.direction,
      with_pairs: true
    })
      .then((r) => actions.initRoute(r))
      .catch((err) => setFlashMessage(err.message));
  }),

  updateStopData: action((state, { stops, stop_location_id, route_id }) => {
    if (state.route?.id !== route_id) return;
    let data = state.schedule.find((s) => s.stop_location.id == stop_location_id);
    data?.stops?.forEach((s) => {
      const updatedStop = stops.find((newData) => newData.date == s.date);
      if (updatedStop) s.stop = updatedStop.stop;
    });
  }),

  resetRoute: action((state) => {
    state.route = null;
    state.schedule = [];
    state.daysOff = [];
    state.stop = null;
  }),

  setRouteAndDirection: action((state, { route, direction }) => {
    state.route = route;
    state.direction = direction;
  }),

  setStopsData: action((state, { stops, days_off, days_delta, route }) => {
    if (stops.every((s) => s.route_id == state.route.id)) {
      // we have to check it the current route haven't changed
      state.schedule = stops;
      state.daysOff = days_off;
      state.daysDelta = days_delta;
    }
    if (route?.id && route.id === state.route.id) {
      assign(state.route, route);
    }
  }),

  updateSchedule: thunk(async (actions, payload, h) => {
    const route = h.getState().route;
    return fetchWeekStops(route.id, payload)
      .then((r) => actions.setStopsData(r))
      .catch((err) => setFlashMessage(err.message));
  }),

  updateVehicleAssignments: thunk(async (actions, payload, h) => {
    const route = h.getState().route;
    return fetchWeekTransport(route.id, payload)
      .then((r) => actions.setTransportData(r))
      .catch((err) => setFlashMessage(err.message));
  }),

  toggleEmptyStops: action((state, _payload) => {
    state.hideEmptyStops = !state.hideEmptyStops;
  })
};

export default routeEditorStore;
