import React from "react";
import { Box } from "@mui/material";
import { flatMap, startsWith, uniq } from "lodash";
import { getAnchorType, getRouteType } from "components/admin_v2/students/stores/stopsModel";

export const getStopMark = (stopsRow) => {
  const stopTypes = uniq(flatMap(stopsRow.stops, (s) => s.stop?.stop_types || []));
  const isAnchor = stopTypes.indexOf("anchor") > -1;
  const tripType = stopsRow.trip_type;
  let type = null;
  if (isAnchor) {
    type = "terminus";
  } else if (
    stopsRow.stops.find((s) => s.destination) ||
    (stopTypes.indexOf("school") > -1 && getRouteType(tripType) === "regular")
  ) {
    type = "intermediate";
  }
  return {
    mark: stopTypes.indexOf("anchor") > -1 ? (getAnchorType("dropoff") > -1 ? "A" : "D") : null,
    type
  };
};

export const directionSign = (tripType) => (startsWith(tripType, "to") ? 1 : -1);

export const stopAddress = (stopLocation) => {
  return (
    <>
      <Box fontWeight="fontWeightBold">{stopLocation.location_name}</Box>
      {stopLocation.address}
    </>
  );
};

export const getAnchorTypeFor = (direction) => (startsWith(direction, "to") ? "dropoff" : "pickup");
