import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import FormControlLabel from "@mui/material/FormControlLabel";
import { capitalize } from "lodash";
import { useLocalStore, useStoreState } from "easy-peasy";
import Spinner from "components/admin_v2/ui/Spinner";
import PaginatedTable from "components/admin_v2/ui/PaginatedTable";
import RouterLink from "components/admin_v2/nav/RouterLink";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import DataManagementTabs from "components/admin_v2/data_management/DataManagementTabs";
import AddRouteImportDialog from "./AddRouteImportDialog";
import { OutlinedBtn } from "components/admin_v2/ui/Buttons";
import { routeImportsStore } from "./stores/routeImportsStore";
import { updateWithRouter, camelizeLocationSearch } from "lib/queryString";
import { pageRoutes } from "services/api";
import useSorting from "lib/useSorting";
import useMessageBus from "lib/useMessageBus";
import I18n from "utils/i18n.js";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";

const STATUS_FILTERS = ["conflicts", "failed", "in-progress", "success"];

const RouteImports = (props) => {
  const cls = useCommonPageStyles();
  const { school, schools } = useStoreState((s) => s.app);
  const store = useLocalStore(() => routeImportsStore(camelizeLocationSearch(props)));
  const [state, actions] = store;

  const { sortBy, sortableColumn } = useSorting({ field: "created_at", order: "desc" });

  useEffect(() => {
    actions.setFromRouter(camelizeLocationSearch(props));
  }, [props.location.search]);

  useEffect(() => {
    if (schools.length === 0) return;

    actions.setNewImport({ ...state.newImport, importableId: school?.id });
  }, [school, schools]);

  useEffect(() => {
    actions.fetchImports({ sortByField: sortBy.field, sortByOrder: sortBy.order });
  }, [state.page, state.perPage, state.status, state.refresh, sortBy]);

  useMessageBus({
    channel: "imports-routes_imports",
    condition: true,
    received: (r) => actions.importChange(r.import)
  });

  const updateQueryString = updateWithRouter(props);

  const updatePage = (e, newPage) => updateQueryString({ page: newPage + 1 });

  const updatePerPage = (e) => {
    updateQueryString({ page: 1, per_page: e.target.value });
  };

  const onSelectStatus = (status) => {
    const newStatus = state.status === status ? null : status;
    updateQueryString({ page: 1, status: newStatus });
  };

  const toggleAddImportDialog = () => {
    actions.setAddImportDialogOpen(!state.addImportDialogOpen);
  };

  const headers = [
    { field: "id", label: "ID" },
    { field: "file", label: "File" },
    { field: "start_date", label: "Effective from" },
    { field: "created_at", label: "Initiated at" },
    { field: "status", label: "Status" },
    { field: "import_errors", label: "Errors", skipSorting: true },
    { field: "details", label: "Details", skipSorting: true }
  ].map(sortableColumn);

  const row = (routeImport) => (
    <TableRow key={`import-${routeImport.id}`}>
      <TableCell component="th" style={{ width: "5%" }}>
        {routeImport.id}
      </TableCell>
      <TableCell style={{ width: "10%" }}>
        <Link href={routeImport.file_path} target="_blank" underline="always">
          {routeImport.file_name}
        </Link>
      </TableCell>
      <TableCell style={{ width: "10%" }}>{routeImport.start_date}</TableCell>
      <TableCell style={{ width: "10%" }}>{routeImport.created_at}</TableCell>
      <TableCell style={{ width: "10%" }}>
        <Box className={`${cls[routeImport.status]} ${cls.statusBox}`}>
          {capitalize(routeImport.status)}
        </Box>
      </TableCell>
      <TableCell style={{ width: "25%" }}>{routeImport.display_errors}</TableCell>
      <TableCell>
        {routeImport.status === "conflicts" ? (
          <RouterLink to={pageRoutes.routeImportConflicts(routeImport.id)}>
            {I18n.t("data_management.actions.resolve_conflicts")}
          </RouterLink>
        ) : (
          <Box>
            {routeImport.nomenclature_changed.length > 0 && (
              <>
                <span>
                  <b>Rejected nomenclature changes:</b>
                </span>
                {routeImport.nomenclature_changed.map((nc, idx) => (
                  <div key={idx}>{nc}</div>
                ))}
              </>
            )}
            {routeImport.details?.map((d, idx) => (
              <div key={`${routeImport.id}-${idx}`}>{d}</div>
            ))}
          </Box>
        )}
      </TableCell>
    </TableRow>
  );

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("data_management.title")} />
      <DataManagementTabs />
      <Box className={cls.btnsWrapper}>
        <OutlinedBtn onClick={toggleAddImportDialog}>
          {I18n.t("data_management.new_route_import.btn")}
        </OutlinedBtn>
      </Box>
      <Paper className={cls.paper}>
        <Box className={cls.filters}>
          {STATUS_FILTERS.map((status) => (
            <FormControlLabel
              key={status}
              className={cls.filter}
              control={
                <GreenCheckbox
                  checked={state.status === status}
                  onChange={() => onSelectStatus(status)}
                />
              }
              label={capitalize(status)}
            />
          ))}
        </Box>
        {state.loading ? (
          <Spinner />
        ) : (
          <PaginatedTable
            headers={headers}
            count={state.total}
            perPage={state.perPage}
            page={state.page}
            onPageChange={updatePage}
            onChangePerPage={updatePerPage}
          >
            {state.imports.map(row)}
          </PaginatedTable>
        )}
      </Paper>
      <AddRouteImportDialog
        store={store}
        cls={cls}
        open={state.addImportDialogOpen}
        toggleDialog={toggleAddImportDialog}
      />
    </React.Fragment>
  );
};

export default RouteImports;
