import { Box, Grid, Paper, Typography } from "@mui/material";
import cn from "classnames";
import BreadCrumbs from "components/admin_v2/common/BreadCrumbs";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import RouterLink from "components/admin_v2/nav/RouterLink";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import CommsGroupAssociations from "components/admin_v2/school_config/comms_config/CommsGroupAssociations";
import SchoolConfigTabs from "components/admin_v2/school_config/SchoolConfigTabs";
import { commsGroupsStore } from "components/admin_v2/school_config/stores/commsGroupsStore";
import { OutlinedBtn } from "components/admin_v2/ui/Buttons";
import Spinner from "components/admin_v2/ui/Spinner";
import { useLocalStore } from "easy-peasy";
import { formatHumanDate } from "lib/dates";
import { formatPhone } from "lib/util";
import { snakeCase, startCase } from "lodash";
import React, { useEffect } from "react";
import { pageRoutes } from "services/api";
import I18n from "utils/i18n.js";

const DETAIL_FIELDS = [
  "name",
  "twilioNumber",
  "replyType",
  "district",
  "school",
  "avlNotifications",
  "createdAt",
  "updatedAt"
];

const CommsGroup = (props) => {
  const cls = useCommonPageStyles();
  const [state, actions] = useLocalStore(() => commsGroupsStore());
  const id = props.match.params.id;
  const group = state.group;

  useEffect(() => {
    if (!id) return;
    actions.fetchCommsGroup(id);

    return () => {
      actions.setGroup(null);
    };
  }, [id]);

  const value = (prop, val) => {
    switch (prop) {
      case "twilioNumber":
        return formatPhone(val);
      case "avlNotifications":
        return val ? I18n.t("ui.positive") : I18n.t("ui.negative");
      case "updatedAt":
      case "createdAt":
        return formatHumanDate(val);
      case "replyType":
        return startCase(val);
      default:
        return val;
    }
  };

  const detail = (prop) => {
    if (["district", "school"].includes(prop) && !group[prop]) return null;

    return (
      <Box className={cls.detail} key={prop} data-test-id="detail">
        <div className={cls.detailHeader}>
          {I18n.t(`school_config.comms_group.labels.${snakeCase(prop)}`)}
        </div>
        <div>{value(prop, group[prop]) || I18n.t("ui.empty_field")}</div>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <TopPageLayout title={I18n.t("school_config.title")} />
      <SchoolConfigTabs />
      <BreadCrumbs
        path={pageRoutes.commsGroups()}
        label={I18n.t("school_config.comms_groups.breadcrumb")}
      />
      {group && (
        <Box className={cls.titleWithBtn}>
          <Typography variant="h1" gutterBottom>
            {group.name}
          </Typography>
          <Box className={cls.btnsWrapper}>
            <RouterLink className={cls.actionBtn} to={pageRoutes.commsGroupEdit(group.id)}>
              <OutlinedBtn>{I18n.t("school_config.edit_comms_group.btn")}</OutlinedBtn>
            </RouterLink>
            <OutlinedBtn className={cls.actionBtn} onClick={() => actions.deleteCommsGroup(id)}>
              {I18n.t("ui.btn.delete")}
            </OutlinedBtn>
          </Box>
        </Box>
      )}
      <Box my={5}>
        <Paper className={cn("comm-group-details", cls.paper)}>
          {state.loading ? (
            <Spinner />
          ) : (
            <>
              <Box className={cls.subTitle}>
                {I18n.t("school_config.comms_group.title.details")}
              </Box>
              <Grid container className={cls.grid} spacing={5}>
                <Grid item xs={6}>
                  {group && DETAIL_FIELDS.map(detail)}
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </Box>
      {group && <CommsGroupAssociations group={group} />}
    </React.Fragment>
  );
};

export default CommsGroup;
