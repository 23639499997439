import React from "react";
import cn from "classnames";
import { Box, FormControlLabel, FormLabel } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { compact, map, omit } from "lodash";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import ErrorList from "components/admin_v2/ui/ErrorList";
import I18n from "utils/i18n.js";

const SchoolsSelectForm = ({ store, cls, withNotifications = true }) => {
  const [state, actions] = store;
  const { details } = state;

  const styles = useStyles();

  const updateSchoolUser = (schoolId, checked) => {
    let schoolsAttrs = details.schoolUsersAttributes;
    // check if there is persisted schoolUser record
    const schoolUser = schoolsAttrs.find((sa) => sa.schoolId === schoolId && sa.id);

    if (checked) {
      // remove _destroy flag if checking persisted record back
      if (schoolUser) {
        schoolsAttrs = schoolsAttrs.map((sa) => {
          return sa.schoolId === schoolId ? omit(sa, "_destroy") : sa;
        });
        // add new record attrs if checking new school
      } else {
        schoolsAttrs.push({ schoolId: schoolId, smsNotifications: false });
      }
    } else {
      // set _destroy flag if unchecked persisted record
      if (schoolUser) {
        schoolsAttrs = schoolsAttrs.map((sa) => {
          return sa.schoolId === schoolId ? { ...sa, _destroy: true } : sa;
        });
        // remove new record from the list if unchecking new school
      } else {
        schoolsAttrs = schoolsAttrs.filter((sa) => sa.schoolId !== schoolId);
      }
    }

    const newDetails = {
      ...details,
      schoolUsersAttributes: schoolsAttrs
    };
    actions.setDetails(newDetails);
  };

  const updateSchoolNotification = (schoolId, checked) => {
    const schoolUser = details.schoolUsersAttributes.find((su) => su.schoolId === schoolId);
    if (!schoolUser) return;

    let schoolsAttrs = details.schoolUsersAttributes;
    schoolsAttrs = schoolsAttrs.map((sa) => {
      return sa.schoolId === schoolId ? { ...sa, smsNotifications: checked } : sa;
    });

    actions.setDetails({
      ...details,
      schoolUsersAttributes: schoolsAttrs
    });
  };

  const isAllChecked = (schools) => schools.every((s) => state.schoolChecked(s.id));

  const toggleAll = (schools) => {
    const allChecked = isAllChecked(schools);
    const schoolIds = schools.map((s) => s.id);
    let schoolsAttrs = details.schoolUsersAttributes;

    if (allChecked) {
      // uncheck all
      schoolsAttrs = compact(
        schoolsAttrs.map((sa) => {
          if (!sa.id) return;
          return schoolIds.includes(sa.schoolId) ? { ...sa, _destroy: true } : sa;
        })
      );
    } else {
      // check all
      schoolsAttrs = [
        ...schoolsAttrs.filter((sa) => !schoolIds.includes(sa.schoolId)),
        ...schools.map((s) => {
          const existing = schoolsAttrs.find((sa) => sa.schoolId === s.id);
          return existing
            ? omit(existing, "_destroy")
            : { schoolId: s.id, smsNotifications: false };
        })
      ];
    }
    actions.setDetails({
      ...details,
      schoolUsersAttributes: schoolsAttrs
    });
  };

  return (
    <>
      <ErrorList errors={state.errors} />
      {map(state.groupedSchools, (schools, districtName) => (
        <Box key={`d-${districtName || "no-district"}`} mt={2}>
          <FormLabel className={cls.label}>
            {districtName || "Without District"}{" "}
            <button className={styles.toggleAll} onClick={() => toggleAll(schools)}>
              {isAllChecked(schools) ? "Unselect all" : "Select all"}
            </button>
          </FormLabel>

          {schools.map((school) => (
            <Box key={school.id} id={`sch-${school.id}`}>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={state.schoolChecked(school.id)}
                    onChange={(e) => updateSchoolUser(school.id, e.target.checked)}
                  />
                }
                classes={{ root: cn("school-select", cls.schoolControl) }}
                label={school.name}
              />
              {withNotifications && (
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={state.smsEnabled(school.id)}
                      onChange={(e) => updateSchoolNotification(school.id, e.target.checked)}
                    />
                  }
                  label={I18n.t("settings_config.users.form.sms_notifications.label")}
                />
              )}
            </Box>
          ))}
        </Box>
      ))}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  toggleAll: {
    fontSize: "0.75rem",
    background: "none",
    border: "none",
    color: theme.custom.BLUE,
    cursor: "pointer",
    marginLeft: "1rem",

    "&:hover": {
      textDecoration: "underline"
    }
  }
}));

export default SchoolsSelectForm;
