import { thunk, thunkOn, action, computed } from "easy-peasy";
import { assign, get, first, isDate, pick, set, cloneDeep, get as lodashGet, keys } from "lodash";
import { camelizeKeys } from "humps";
import { easyStateSetters } from "lib/easyState";
import { parseISO, isEqual, isSameDay } from "date-fns";
import { formatDateUrl, formatTime, parseTime, dateInTimeZone, formatSimpleDate } from "lib/dates";
import { toJS } from "lib/util";
import { getStudentCalendarDay } from "services/apiStudents";
import { resolveChangeReq } from "services/apiChangeRequests";
import { ROUTABLE_TYPES } from "components/admin_v2/students/stores/addTripStore";
import {
  stopsModel,
  getAnchorType,
  getStopType,
  getRouteType,
  getStopWorkflow
} from "components/admin_v2/students/stores/stopsModel";
import I18n from "utils/i18n.js";

export const defaultState = {
  // dialog data
  calendar: null,
  changeReq: null,
  trip: null,
  // dialog status
  loading: false,
  // dialog state
  eventType: "change",
  errors: {},
  // change request status
  status: null,
  approverNote: null,
  // period
  period: {
    startDate: null,
    endDate: null,
    onlyDateChecked: null,
    days: []
  },
  // route
  route: null,
  // service states (in use to reset autocomplete)
  routeReset: false,
  routableType: ROUTABLE_TYPES.singleSchool,
  // options for school stops
  routableSchools: [],
  // need this only on route creation to redirect after dialog close. Route itself will be created at
  // inlined subdialog
  createdRouteId: null,
  // shaped same way as addTripStore stops
  stops: {},
  // workflows: select/new route, stops: select/new stops, addresses: select/new/school
  workflow: { createRoute: false, stops: {} }
};

const initStateFromChangeRequest = (changeReq, effectivePeriod = {}) => {
  if (!changeReq) return {};
  const period = {
    onlyDateChecked: changeReq.start_date === changeReq.end_date,
    startDate: effectivePeriod.startDate || parseISO(changeReq.start_date),
    endDate: effectivePeriod.endDate || parseISO(changeReq.end_date),
    days: effectivePeriod.days || changeReq.days_of_week
  };
  // const STOP_DEFAULT = {
  //   id: null,
  //   schoolAddress: false,
  //   masterId: null,
  //   stopLocation: {
  //     id: null,
  //     address: null
  //   },
  //   time: null
  //   timeId: null (in use in regular routes anchors only)
  // };
  const stopType = getStopType(changeReq.trip_type);
  let stops = {};
  let newStop = get(camelizeKeys(changeReq.stops), "stop");
  if (newStop) {
    newStop.time = parseTime(newStop.time);
    newStop.id = null;
  }
  stops[changeReq.trip_type] = {
    [stopType]: changeReq.to_stop || newStop
  };
  let workflow = { ...defaultState.workflow };
  workflow.stops[changeReq.trip_type] = {
    [stopType]: changeReq.to_stop ? "chooseStop" : getStopWorkflow(newStop)
  };
  return {
    changeReq,
    createdRouteId: null,
    route: changeReq.route,
    status: changeReq.status,
    errors: {},
    period,
    stops,
    workflow
  };
};

const requestStore = () => ({
  ...easyStateSetters(defaultState),
  ...stopsModel(),

  // computed properties
  anchorData: computed((state) => {
    if (!state.changeReq) return null;
    if (state.changeReq.stops?.anchor) return state.changeReq.stops.anchor;

    const anchors = lodashGet(state.changeReq, `route.anchors.${state.changeReq.trip_type}`, []);
    return state.getRouteAnchorFrom(anchors);
  }),
  anchorType: computed((state) =>
    state.changeReq ? getAnchorType(state.changeReq.trip_type) : ""
  ),
  displayStatus: computed((state) =>
    ["rejected", "approved", "pending"].includes(state.status) ? state.status : ""
  ),
  activeStatus: computed((state) => ["new", "approved"].includes(state.status)),
  effectiveDate: computed((state) => state.period?.startDate),
  minDate: computed((state) => dateInTimeZone(new Date(), state.school?.time_zone_offset)),
  getTopLevelErrors: computed((state) => get(state.errors, "base")),
  getTrip: computed((state) => state.trip?.student_add || state.trip),
  // calculate if we can submit or not
  isDisabled: computed((state) => {
    if (state.loading) return true;
    const disabledStatus = ["new", ""].includes(state.status);
    if (state.isUnenroll) return disabledStatus;
    if (["rejected", "pending"].includes(state.status)) return false;
    if (!state.route?.id) return true;
    if (keys(state.stops).length < state.tripTypes.length) return true;
    return disabledStatus;
  }),
  isUnenroll: computed((state) => state.changeReq?.event_type === "unenroll"),
  oneDayChange: computed(
    (state) =>
      state.period &&
      state.period.endDate != "" &&
      isEqual(state.period.startDate, state.period.endDate)
  ),
  resolved: computed((state) =>
    ["approved", "rejected", "cancelled"].includes(state.changeReq?.status)
  ),
  routeType: computed((state) =>
    state.changeReq ? getRouteType(state.changeReq.trip_type) : "regular"
  ),
  school: computed((state) => state.changeReq?.school),
  student: computed((state) => state.calendar?.student || state.changeReq?.student),
  showEventBadge: computed(
    (state) =>
      !state.isUnenroll &&
      !state.isDisabled &&
      state.oneDayChange &&
      ["approved", "pending"].includes(state.status)
  ),
  stopType: computed((state) => (state.changeReq ? getStopType(state.changeReq.trip_type) : null)),
  tripTypes: computed((state) => (state.changeReq ? [state.changeReq.trip_type] : [])),

  // actions
  setNewRoute: action((state, route) => {
    state.route = route;
    state.createdRouteId = route.id;
    set(state.stops, `${state.changeReq.trip_type}.${state.stopType}.id`, null);
  }),

  onSetChangeReq: thunkOn(
    (actions) => actions.setChangeReq,
    (actions, target) => {
      const changeReq = target.payload;
      actions.fetchCalendar({ changeReq });
    }
  ),

  fetchCalendar: thunk((actions, payload, h) => {
    const state = h.getState();
    const changeReq = payload?.changeReq || state.changeReq;

    if (state.isUnenroll) {
      actions.setEffectiveDate(parseISO(changeReq.start_date));
      return;
    }

    actions.setLoading(true);

    return getStudentCalendarDay(
      changeReq.student?.id || changeReq.student_id,
      formatDateUrl(payload?.withPeriod ? state.period.startDate : changeReq.start_date),
      {
        tripType: changeReq.trip_type,
        changeReqId: changeReq.id,
        stopId: changeReq.status === "approved" ? changeReq.to_stop_id : changeReq.from_stop_id
      }
    )
      .then((r) => {
        actions.updateTrip({
          calendar: r,
          effectivePeriod: payload?.withPeriod ? state.period : null
        });
      })
      .finally(() => {
        actions.setLoading(false);
      });
  }),

  // in use at common components like Route/Stop select etc
  resetState: action((state, params = {}) => {
    if (get(state.stops, `${state.changeReq.trip_type}.${state.stopType}.id`)) {
      set(state.stops, `${state.changeReq.trip_type}.${state.stopType}`, null);
    }
    assign(
      state,
      // params that we're overwriting
      params
    );
  }),

  resetStateToDefault: action((state) => {
    assign(state, defaultState);
  }),

  updateEffectiveDate: thunk((actions, payload, _h) => {
    actions.setEffectiveDate(payload);
    actions.fetchCalendar({ withPeriod: true });
  }),

  updateTrip: action((state, { calendar, effectivePeriod }) => {
    state.calendar = calendar;
    const stops = calendar.trips[state.changeReq.trip_type]?.stops || [
      calendar.trips[state.changeReq.trip_type]
    ];
    state.trip =
      stops.find((s) => s?.change_request?.id === state.changeReq.id) || first(stops) || {};
    const newState = initStateFromChangeRequest(
      state.trip.change_request || state.changeReq,
      effectivePeriod || {}
    );
    assign(state, newState);
  }),

  setEffectiveDate: action((state, date) => {
    if (isSameDay(state.period.startDate, state.period.endDate)) {
      state.period.startDate = date;
      state.period.endDate = date;
    } else {
      state.period.startDate = date;
    }
  }),

  save: thunk((actions, _, h) => {
    actions.setLoading(true);
    const state = h.getState();

    let stop = cloneDeep(state.getStopFor(state.changeReq.trip_type, state.stopType));

    try {
      if (isDate(stop?.time) && !stop.id) stop.time = formatTime(stop.time);
    } catch (e) {
      const data = { message: I18n.t("route.errors.dates.invalid") };
      return Promise.reject({ response: { data } });
    }

    if (
      !state.isUnenroll &&
      state.activeStatus &&
      !isSameDay(state.period.startDate, state.minDate) &&
      state.period.startDate < state.minDate
    ) {
      const data = {
        message: I18n.t("change_requests.errors.effective_date_in_past", {
          date: formatSimpleDate(state.minDate)
        })
      };
      return Promise.reject({ response: { data } });
    }

    let apiParams = {
      route_id: state.route?.id,
      school_id: state.school.id,
      days_of_week: state.period.days,
      to_stop: stop,
      ...pick(state, ["approverNote", "status", "eventType"])
    };

    if (state.oneDayChange) {
      apiParams.end_date = formatDateUrl(state.period.endDate);
    }
    if (state.isUnenroll) {
      apiParams.unenroll = {
        date: formatDateUrl(state.period.startDate),
        student_id: state.changeReq.student.id
      };
    } else {
      apiParams.startDate = formatDateUrl(state.period.startDate);
    }

    const data = toJS({ change_req: apiParams });
    return resolveChangeReq(state.changeReq.id, data).finally(() => actions.setLoading(false));
  })
});

export default requestStore;
