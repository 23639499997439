import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useStoreState } from "easy-peasy";
import React from "react";
import ScopeBtn from "../ui/ScopeBtn";
import { useStyles } from "./DirectionBtns";

const RouteDirectionBtns = ({ onSelect, flex = 1 }) => {
  const cls = useStyles({ flex });
  const { direction, route } = useStoreState((s) => s.routeEditor);

  if (!route) return null;

  const activeScope = (tripType) => {
    return tripType === direction ? "active" : null;
  };

  // We want the "to" directions, to appear ahead of the "from" directions
  const routeDirections = route.directions
    .toSorted((a, b) => a.type.localeCompare(b.type))
    .toReversed();
  const directions = routeDirections.map((d) => (
    <ScopeBtn
      key={`sb-${d.type}`}
      size="large"
      active={activeScope(d.type)}
      onClick={() => onSelect(d.type)}
    >
      {d.label}
    </ScopeBtn>
  ));

  return (
    <Box className={cls.directionBtns}>
      <Typography component="span" variant="subtitle2">
        {directions}
      </Typography>
    </Box>
  );
};

export default RouteDirectionBtns;
