import xhr from "lib/xhr";
import { queryString } from "lib/util";
import { apiUrl } from "./api";

export const fetchTrips = async (params = {}) => {
  const query = new URLSearchParams(queryString(params, true));
  return await xhr.get(apiUrl(`trips?${query}`));
};

export const refetchTrips = async (params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`trips/refetch?${query}`));
};

export const fetchSearchOptions = async (params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`trips/search_options?${query}`));
};

export const fetchTrip = async (id) => {
  return await xhr.get(apiUrl(`trips/${id}`));
};

export const fetchTripMap = async (id) => {
  return await xhr.get(apiUrl(`trips/${id}/map`));
};

export const fetchTripDirections = async (id) => {
  return await xhr.get(apiUrl(`trips/${id}/directions`));
};

export const updateTrip = async (id, tripParams, periodParams) => {
  const url = apiUrl(`trips/${id}`);
  return await xhr.post(url, { trip: tripParams, period: periodParams });
};

export const simulateTracking = async (id, params) => {
  return await xhr.post(apiUrl(`trips/${id}/simulate_tracking`), { tracking: params });
};

export const toggleTracking = async (id) => {
  return await xhr.post(apiUrl(`trips/${id}/toggle_tracking`));
};
