import { queryString } from "lib/util";
import xhr from "lib/xhr";
import { apiUrl } from "./api";
import { decamelizeKeys } from "humps";

export const fetchSchools = async (params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`schools?${query}`));
};

export const fetchSchoolsList = async (params = {}) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`schools/list?${query}`));
};

export const fetchSchoolDismissals = async (schoolId) => {
  return await xhr.get(apiUrl(`schools/${schoolId}/dismissals`));
};

export const fetchSchoolGroups = async (schoolId) => {
  return await xhr.get(apiUrl(`schools/${schoolId}/groups`));
};

export const fetchSchool = async (id) => {
  return await xhr.get(apiUrl(`schools/${id}`), { transformData: true });
};

export const fetchSchoolsData = async (params) => {
  const query = queryString(params);
  return await xhr.get(apiUrl(`schools/data?${query}`));
};

export const saveSchool = async (id, params) => {
  const url = apiUrl(`schools/${id}`);
  return await xhr.post(url, { school: params }, { transformData: true });
};

export const createSchool = async (params) => {
  const url = apiUrl(`schools`);
  return await xhr.post(url, { school: params });
};

export const archiveSchool = async (id) => {
  const url = apiUrl(`schools/${id}/archive`);
  return await xhr.post(url);
};

export const batchArchive = async (ids) => {
  const url = apiUrl(`schools/batch_archive`);
  return await xhr.post(url, { school_ids: ids });
};

export const batchUnarchive = async (ids) => {
  const url = apiUrl(`schools/batch_unarchive`);
  return await xhr.post(url, { school_ids: ids });
};

export const addSlackIntegration = async (id) => {
  const url = apiUrl(`schools/${id}/add_slack`);
  return await xhr.post(url);
};

export const fetchContactOptions = async (params) => {
  const query = new URLSearchParams(decamelizeKeys(params));
  const url = apiUrl(`schools/contact_options?${query}`);
  return await xhr.get(url);
};

export const fetchRouteOptions = async (id, params) => {
  const query = queryString(params, true);
  return await xhr.get(apiUrl(`schools/${id}/route_options?${query}`));
};
