import React, { useEffect, useMemo } from "react";
import { map, startCase, snakeCase, camelCase } from "lodash";
import { parseISO, isPast, isToday } from "date-fns";
import {
  TextField,
  FormControl,
  FormLabel,
  Typography,
  Box,
  Link,
  OutlinedInput,
  Grid,
  Divider,
  FormControlLabel,
  RadioGroup
} from "@mui/material";
import { Info, CancelRounded, EditOutlined, CheckCircleRounded } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import cn from "classnames";
import { useStoreState } from "easy-peasy";
import { pageRoutes, slugsFor } from "services/api";
import RouterLink from "components/admin_v2/nav/RouterLink";
import I18n from "utils/i18n.js";
import DatePickerWithPeriod from "components/admin_v2/ui/DatePickerWithPeriod";
import WarningLabel from "components/admin_v2/common/WarningLabel";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import GreenRadio from "components/admin_v2/ui/GreenRadio";
import useDebounce from "lib/useDebounce";
import { TRIP_DIRECTIONS, PERIOD_DATE_OPTIONS } from "utils/constants";

const BOOKINGS_PROVIDERS = ["icabbi", "cabcall", "ztrip", "cts"];
const VEHICLE_RE = /(Vehicle)$/;
const VEHICLE_DETAILS = [
  { field: "licensePlate", width: "40%" },
  { field: "vehicleType", width: "25%" },
  { field: "capacity", width: "25%" },
  { field: "source", width: "45%" },
  { field: "trackingDevice", width: "45%" }
];
const APPLY_OPTIONS = [
  {
    id: true,
    label: I18n.t(`data_management.assigned_vehicles.form.apply_to_pair.positive`)
  },
  {
    id: false,
    label: I18n.t(`data_management.assigned_vehicles.form.apply_to_pair.negative`)
  }
];

const VendorScheduleForm = ({ cls, store, route, page, trip, disabledForStaff = false }) => {
  const [state, actions] = store;
  const { form, note, errors, options, inboundVehicle, outboundVehicle } = state;
  const { user } = useStoreState((s) => s.app);
  const permanentChangeAllowed =
    page !== "vendors_dashboard" || user?.vendor_id === route?.permanent_vendor_id;
  const dateOptionsAvailable = permanentChangeAllowed
    ? PERIOD_DATE_OPTIONS
    : PERIOD_DATE_OPTIONS.filter((o) => !o.id);
  const bookingTrip = BOOKINGS_PROVIDERS.includes(trip?.source);
  const inboundSectionCls = cn({
    [cls.disabled]: !form.permanent && !form.applyInbound && !bookingTrip
  });
  const outboundSectionCls = cn({
    [cls.disabled]: !form.permanent && !form.applyOutbound && !bookingTrip
  });
  const debouncedNote = useDebounce(note);
  const pastDate = trip
    ? isPast(parseISO(trip.date_iso)) && !isToday(parseISO(trip.date_iso))
    : false;

  useEffect(() => {
    actions.setInboundVehicle(options.vehicles.find((v) => v.id === form.inboundVehicleId));
    actions.setOutboundVehicle(options.vehicles.find((v) => v.id === form.outboundVehicleId));
  }, [form.inboundVehicleId, form.outboundVehicleId, options.vehicles]);

  const changeField = (field) => (evt) => {
    actions.updateForm({ field, evt });
  };

  useEffect(() => {
    if (!debouncedNote) return;

    changeField("note")(debouncedNote);
  }, [debouncedNote]);

  const selectOptions = useMemo(() => {
    return ({ field, disabled = false, filter = true, half = false }) => {
      const itemsLabelKey = field.match(VEHICLE_RE) ? "vehicle" : "transportation_vendor";
      const itemsKey = `${itemsLabelKey}s`;
      let items = options[itemsKey];

      if (filter && form.transportationVendorId) {
        items = items.filter((v) => v.transportation_vendor_id === form.transportationVendorId);
      }
      const fieldId = `${camelCase(field)}Id`;
      items = [
        {
          id: null,
          name: I18n.t("data_management.assigned_vehicles.no_vehicle")
        },
        ...items
      ];

      const selected = items?.find((item) => form[fieldId] === item.id) || null;
      const anotherVendor = items.length > 1 && form[fieldId] && !selected;

      return (
        <>
          <FormControl className={cn(cls.field, { [cls.halfSelect]: half })}>
            <FormLabel className={cls.label} required={true}>
              {I18n.t(`data_management.assigned_vehicles.form.${itemsLabelKey}.label`)}
            </FormLabel>
            {anotherVendor ? (
              <WarningLabel
                label={I18n.t("data_management.assigned_vehicles.warnings.another_vendor")}
              />
            ) : (
              <Autocomplete
                id={`vendor-schedule-${field}`}
                size="small"
                options={items}
                getOptionLabel={(item) => item.name || ""}
                value={selected}
                onChange={(_, value) => changeField(fieldId)(value)}
                disabled={disabled}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    {...params}
                    fullWidth
                    error={!!errors?.[fieldId]}
                    helperText={errors?.[fieldId]}
                  />
                )}
              />
            )}
          </FormControl>
        </>
      );
    };
  }, [
    state.options,
    state.form.transportationVendorId,
    state.form.inboundVehicleId,
    state.form.outboundVehicleId
  ]);

  if (!route) return null;

  const invalidVehicleType = (vehicleType) => {
    if (!vehicleType) return false;

    if (vehicleType !== route.vehicle_type) {
      return (
        <WarningLabel
          label={I18n.t("data_management.assigned_vehicles.warnings.vehicle_type", {
            vehicle_type: startCase(route.vehicle_type)
          })}
        />
      );
    } else {
      return null;
    }
  };

  const directionIsDisabled = (direction) => {
    if (page !== "vendors_dashboard") return false;
    if (trip.permanent_vendor_schedule) return false;
    if (route[`${direction}_vendor_id`] === user.transportation_vendor_id) return false;

    return true;
  };

  const bookingId = (direction) => {
    if (!trip || !bookingTrip) return null;

    const booking =
      TRIP_DIRECTIONS[trip.trip_type] === direction ? state.bookingId || "---" : "---";
    const showEditBooking =
      (trip.booking || trip.ztrip) && TRIP_DIRECTIONS[trip.trip_type] === direction;

    return (
      <Box key={`vehicle-booking-id`} style={{ width: "100%" }}>
        <FormLabel>{I18n.t("data_management.assigned_vehicles.details.booking_id")}</FormLabel>
        <Box className={cls.bookingActions}>
          {showEditBooking && state.bookingEdit ? (
            <FormControl>
              <OutlinedInput
                value={state.bookingId || ""}
                onChange={(e) => {
                  actions.setBookingId(e.target.value);
                }}
              />
            </FormControl>
          ) : (
            <Typography className={cls.detailsBold}>{booking}</Typography>
          )}
          {showEditBooking && !state.bookingEdit && (
            <Link className={cls.actionIcon} onClick={() => actions.setBookingEdit(true)}>
              <EditOutlined />
            </Link>
          )}
          {showEditBooking && state.bookingEdit && (
            <>
              <Link
                className={cls.actionIcon}
                onClick={() => actions.submitBookingId({ tripId: trip.id, page: page })}
              >
                <CheckCircleRounded />
              </Link>
              <Link className={cls.actionIcon} onClick={() => actions.setBookingEdit(false)}>
                <CancelRounded />
              </Link>
            </>
          )}
        </Box>
      </Box>
    );
  };

  const pairedRouteInfo = (pairedRoute, condition, field) => {
    if (!pairedRoute) return null;

    return form.permanent ? (
      <>
        <Box display="flex" mt={2}>
          <Info className={cls.infoIcon} />
          <Typography variant="body2">
            {I18n.t("data_management.assigned_vehicles.warnings.paired_route")}
            {page === "vendors_dashboard" ? (
              pairedRoute.name
            ) : (
              <RouterLink
                to={pageRoutes.route(pairedRoute.id, slugsFor(pairedRoute))}
                target="_blank"
                v6={page === "vendors_dashboard"}
              >
                {pairedRoute.name}
              </RouterLink>
            )}
          </Typography>
        </Box>
        {page !== "vendors_dashboard" && (
          <Box ml={4} mt={2}>
            <Typography variant="body2" className={cls.smallLabel}>
              {I18n.t("data_management.assigned_vehicles.warnings.unpair")}
              <RouterLink
                to={pageRoutes.pairedRoutes}
                target="_blank"
                v6={page === "vendors_dashboard"}
              >
                {I18n.t("data_management.assigned_vehicles.warnings.unpair_link")}
              </RouterLink>
            </Typography>
          </Box>
        )}
      </>
    ) : (
      <>
        <Box display="flex" mt={2} className={cls.noWrap}>
          <FormLabel className={cls.smallLabel}>
            {I18n.t("data_management.assigned_vehicles.form.apply_to_pair.label")}
          </FormLabel>
          <FormLabel className={cn(cls.label, cls.ml, cls.smallLabel)}>
            <i>{pairedRoute.name}</i>
          </FormLabel>
        </Box>
        <RadioGroup value={form[field]} onChange={changeField(field)} row>
          {map(APPLY_OPTIONS, (opt) => (
            <FormControlLabel
              value={opt.id}
              key={`apply-${opt.label}`}
              control={<GreenRadio />}
              label={opt.label}
              disabled={!condition}
            />
          ))}
        </RadioGroup>
      </>
    );
  };

  return (
    <>
      <FormControl className={cls.withTopMargin} fullWidth>
        <FormLabel className={cls.label}>{I18n.t("ui.form.route_name.label")}</FormLabel>
        <Typography>{route.name}</Typography>
      </FormControl>
      <DatePickerWithPeriod
        selectedDate={form.startDate}
        periodValue={form.permanent}
        onChangeDate={changeField("startDate")}
        onChangePeriod={changeField("permanent")}
        dateOptionsAvailable={dateOptionsAvailable}
        label={I18n.t(`data_management.assigned_vehicles.form.dates.label`)}
        disablePast={true}
        disabledForStaff={pastDate || disabledForStaff}
      />
      {selectOptions({
        field: "transportation_vendor",
        filter: false,
        half: true,
        disabled: page === "vendors_dashboard" || disabledForStaff
      })}
      {
        <Grid container>
          <Grid item xs={5} mr={6}>
            {form.permanent ? (
              <Typography>
                {I18n.t("data_management.assigned_vehicles.sections.inbound")}
              </Typography>
            ) : (
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={form.applyInbound}
                    onChange={changeField("applyInbound")}
                    disabled={directionIsDisabled("inbound")}
                  />
                }
                label={I18n.t("data_management.assigned_vehicles.sections.inbound")}
              />
            )}
            <Box className={inboundSectionCls}>
              {selectOptions({
                field: "inboundVehicle",
                disabled: (!form.permanent && !form.applyInbound) || disabledForStaff
              })}
              {invalidVehicleType(inboundVehicle?.vehicle_type)}
              <Box className={cls.detailsFlex}>
                {VEHICLE_DETAILS.map((dt) => (
                  <Box key={`vehicle-${dt.field}`} style={{ width: dt.width }}>
                    <FormLabel>
                      {I18n.t(`data_management.assigned_vehicles.details.${snakeCase(dt.field)}`)}
                    </FormLabel>
                    <Typography className={cls.detailsBold}>
                      {(dt.field === "tracking_device"
                        ? inboundVehicle?.tracking_device?.name
                        : startCase(inboundVehicle?.[snakeCase(dt.field)])) || "---"}
                    </Typography>
                  </Box>
                ))}
                {bookingId("inbound")}
              </Box>
              {pairedRouteInfo(route.inbound_pair?.route, form.applyInbound, "applyInboundPair")}
            </Box>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs={5} ml={6}>
            {form.permanent ? (
              <Typography>
                {I18n.t("data_management.assigned_vehicles.sections.outbound")}
              </Typography>
            ) : (
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={form.applyOutbound}
                    onChange={changeField("applyOutbound")}
                    disabled={directionIsDisabled("outbound")}
                  />
                }
                label={I18n.t("data_management.assigned_vehicles.sections.outbound")}
              />
            )}
            <Box className={outboundSectionCls}>
              {selectOptions({
                field: "outboundVehicle",
                disabled: (!form.permanent && !form.applyOutbound) || disabledForStaff
              })}
              {invalidVehicleType(outboundVehicle?.vehicle_type)}
              <Box className={cls.detailsFlex}>
                {VEHICLE_DETAILS.map((dt) => (
                  <Box key={`vehicle-${dt.field}`} style={{ width: dt.width }}>
                    <FormLabel>
                      {I18n.t(`data_management.assigned_vehicles.details.${snakeCase(dt.field)}`)}
                    </FormLabel>
                    <Typography className={cls.detailsBold}>
                      {(dt.field === "tracking_device"
                        ? outboundVehicle?.tracking_device?.name
                        : startCase(outboundVehicle?.[snakeCase(dt.field)])) || "---"}
                    </Typography>
                  </Box>
                ))}
                {bookingId("outbound")}
              </Box>
              {pairedRouteInfo(route.outbound_pair?.route, form.applyOutbound, "applyOutboundPair")}
            </Box>
          </Grid>
        </Grid>
      }
      <TextField
        variant="outlined"
        multiline
        minRows={2}
        maxRows={2}
        className={cls.field}
        onChange={actions.setNote}
        placeholder={I18n.t("data_management.assigned_vehicles.form.notes.label")}
        defaultValue={form.note}
        disabled={disabledForStaff}
      />
    </>
  );
};

export default VendorScheduleForm;
