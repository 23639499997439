import React from "react";
import { Box, Chip, TableCell, TableRow } from "@mui/material";
import { capitalize, find } from "lodash";
import { pageRoutes, slugsFor } from "services/api";
import EventSimpleSelect from "components/admin_v2/common/EventSimpleSelect";
import RouterLink from "components/admin_v2/nav/RouterLink";
import CurbsideStopCell from "./CurbsideStopCell";
import { useStoreState } from "easy-peasy";
import useStyles from "./useStyles";
import { tableCellType, EventLabel, IconCell } from "components/admin_v2/common/EventHelpers";
import {
  StudentAccommodationPopup,
  withoutAccommodation
} from "components/admin_v2/ui/StudentAccommodation";
import { isOtherRoute } from "components/admin_v2/students/stores/stopsModel";

const getEventType = (trip) => {
  if (trip.change_request) return "change_req";
  const eventType = find(
    ["activity", "no_show", "cancel", "change", "one_off", "student_add"],
    (key) => trip.events[key]
  );
  // if (eventType === "student_add" && trip.has_one_off) return "one_off";
  return eventType || "ok";
};

const vehicleType = (route) => {
  if (!route) return "";
  return route.vehicle_type === "distance" ? "N/A" : capitalize(route.vehicle_type);
};

const CurbsideRow = ({
  date,
  dayOff,
  trip,
  isCustomTripType,
  updateStudentAttendance,
  openModeDialog,
  openChangeReqDialog
}) => {
  const { isUserAdmin } = useStoreState((s) => s.app);
  const eventType = getEventType(trip);
  const baseEventType = eventType === "student_add" && trip.has_one_off ? "one_off" : eventType;
  const isOther = isOtherRoute(trip.route?.route_type || trip.trip_type);
  const cls = useStyles();

  const changeRow =
    (trip.events.cancel || trip.events.change || trip.events.one_off) && trip.events.student_add;
  const disabledSelect =
    !!trip.change_request ||
    changeRow ||
    ((dayOff || trip.student?.day_off) && !isOther) ||
    trip.route?.discarded;

  const phoneCell = trip.student.primary_phone_number
    ? trip.student.primary_phone_number.number
    : "None";
  const phoneCount =
    trip.student.phone_numbers_size > 1 ? (
      <Chip
        className={`${cls.leftSpacingSm} ${cls.phoneIndicator}`}
        size="small"
        label={trip.student.phone_numbers_size}
      />
    ) : (
      ""
    );

  const eventsCell = (
    <EventSimpleSelect
      eventType={baseEventType}
      isOtherRoute={isOther}
      startTime={trip.route?.start_time}
      onChange={updateStudentAttendance(trip, eventType)}
      date={date}
      schoolId={trip.student.school_id}
      disabled={!!disabledSelect}
      dayOff={trip.student.day_off}
      isUnassigned={!trip.route}
      withActivity={trip.with_activity}
      events={trip.events.data}
      changeRequestId={trip.change_request?.id}
      onChangeRequest={() => (isUserAdmin ? openChangeReqDialog(trip) : openModeDialog(trip))}
    />
  );

  const EventTableCell = tableCellType(eventType);

  const openModeDialogForStudent = (e) => {
    e.preventDefault();
    if (trip.route?.discarded) return;
    openModeDialog(trip);
  };

  const customStops = () => {
    if (!isCustomTripType || !isOther) return [];

    const stops = trip.anchor?.stop_types.includes("dropoff")
      ? [trip.stop, trip.anchor]
      : [trip.anchor, trip.stop];
    return stops.map((stop, idx) => (
      <CurbsideStopCell
        dayOff={dayOff || trip.student?.day_off}
        key={`s-${stop?.id || `ch-${idx}`}`}
        trip={trip}
        stop={stop}
        openModeDialogForStudent={openModeDialogForStudent}
        EventTableCell={EventTableCell}
      />
    ));
  };

  return (
    <TableRow className={isOther && !isCustomTripType ? cls.customTrip : ""}>
      <IconCell>
        {withoutAccommodation(trip.student) ? null : (
          <StudentAccommodationPopup student={trip.student} />
        )}
      </IconCell>
      <EventTableCell>
        <RouterLink to={pageRoutes.student(trip.student.id, slugsFor(trip.student))}>
          {trip.student.last_name}
        </RouterLink>
      </EventTableCell>
      <EventTableCell>{trip.student.first_name}</EventTableCell>
      <EventTableCell>{trip.school}</EventTableCell>
      <EventTableCell>
        {trip.route ? (
          <RouterLink to={pageRoutes.route(trip.route.id, slugsFor(trip.route))}>
            {trip.route.name}
          </RouterLink>
        ) : (
          "Unassigned"
        )}
      </EventTableCell>
      <EventTableCell>{vehicleType(trip.route)}</EventTableCell>
      {isCustomTripType ? (
        customStops()
      ) : (
        <CurbsideStopCell
          dayOff={dayOff || trip.student?.day_off}
          trip={trip}
          stop={
            isOther && trip.route && trip.stop.stop_types.includes("school")
              ? trip.anchor
              : trip.stop
          }
          openModeDialogForStudent={openModeDialogForStudent}
          EventTableCell={EventTableCell}
        />
      )}
      <EventTableCell>{trip.student.grade}</EventTableCell>
      <EventTableCell>{trip.student.program}</EventTableCell>
      <EventTableCell>
        <Box className={cls.phoneCell}>
          {phoneCell}
          {phoneCount}
        </Box>
      </EventTableCell>
      <EventTableCell className={cls.eventCell}>
        <EventLabel addEvent={eventType === "student_add"} trip={trip} eventType={eventType} />
      </EventTableCell>
      <TableCell>{eventsCell}</TableCell>
    </TableRow>
  );
};

export default CurbsideRow;
