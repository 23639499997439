import React, { Fragment } from "react";
import cn from "classnames";
import { startCase } from "lodash";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import { strPresent } from "lib/util";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import RequiredLabel from "components/admin_v2/ui/RequiredLabel";
import I18n from "utils/i18n.js";

const PROVIDERS = [
  "drivers",
  "cabcall",
  "osg",
  "icabbi",
  "zonar",
  "geotab",
  "samsara",
  "trax",
  "azuga",
  "zubie",
  "ztrip",
  "cts",
  "quartix",
  "adroit",
  "motive",
  "synovia"
];

const PROVIDERS_WITH_URL = ["cabcall", "geotab"];
const PROVIDERS_WITH_KEY = ["osg", "samsara", "trax", "azuga", "cts", "adroit", "synovia"];
const PROVIDERS_WITH_USERNAME = ["zonar", "cabcall", "geotab", "ztrip", "quartix", "synovia"];
const PROVIDERS_WITH_CUSTOMER = ["zonar", "ztrip", "adroit", "synovia"];

const VendorDetailsForm = ({ formStore }) => {
  const cls = useCommonPageStyles();
  const [state, actions] = formStore;
  const { details, errors } = state;

  if (!details) return null;

  const updateField = (key, e) => {
    const newDetails = { ...details, [key]: e.target.value };
    actions.setDetails(newDetails);
  };

  const updateCredentialsField = (key, e) => {
    const cred = { ...details.vendorCredentialAttributes, [key]: e.target.value };
    actions.setDetails({ ...details, vendorCredentialAttributes: cred });
  };

  return (
    <Fragment>
      <Grid container spacing={5}>
        <Grid item xs={9}>
          <TextField
            variant="standard"
            label={
              <RequiredLabel
                label={I18n.t("vendor_config.vendors.form.name.label")}
                condition={strPresent(details.name)}
              />
            }
            value={details.name || ""}
            onChange={(e) => updateField("name", e)}
            className={cn("vendor-name", cls.field)}
            error={!!errors?.name}
            helperText={errors?.name?.[0]}
          />
          <TextField
            variant="standard"
            label={I18n.t("vendor_config.vendors.form.email.label")}
            value={details.email || ""}
            onChange={(e) => updateField("email", e)}
            className={cls.field}
            error={!!errors?.email}
            helperText={errors?.email?.[0]}
          />
          <FormControl className={cls.field} variant="standard">
            <InputLabel>{I18n.t(`vendor_config.vendors.form.provider.label`)}</InputLabel>
            <Select
              value={details?.provider || "drivers"}
              onChange={(e) => updateField("provider", e)}
            >
              {PROVIDERS.map((provider) => (
                <MenuItem key={`vendor-${provider}`} value={provider}>
                  {startCase(provider)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {PROVIDERS_WITH_URL.includes(details.provider) && (
            <TextField
              variant="standard"
              label={
                <RequiredLabel
                  label={I18n.t("vendor_config.vendors.form.api_url.label")}
                  condition={strPresent(details.vendorCredentialAttributes.apiUrl)}
                />
              }
              value={details.vendorCredentialAttributes.apiUrl || ""}
              onChange={(e) => updateCredentialsField("apiUrl", e)}
              className={cls.field}
              error={!!errors?.["vendorCredential.apiUrl"]}
              helperText={errors?.["vendorCredential.apiUrl"]?.[0]}
            />
          )}
          {PROVIDERS_WITH_KEY.includes(details.provider) && (
            <TextField
              variant="standard"
              label={
                <RequiredLabel
                  label={I18n.t("vendor_config.vendors.form.api_key.label")}
                  condition={strPresent(details.vendorCredentialAttributes.apiKey)}
                />
              }
              value={details.vendorCredentialAttributes.apiKey || ""}
              onChange={(e) => updateCredentialsField("apiKey", e)}
              className={cls.field}
              error={!!errors?.["vendorCredential.apiKey"]}
              helperText={errors?.["vendorCredential.apiKey"]?.[0]}
            />
          )}
          {PROVIDERS_WITH_CUSTOMER.includes(details.provider) && (
            <TextField
              variant="standard"
              label={
                <RequiredLabel
                  label={I18n.t("vendor_config.vendors.form.customer.label")}
                  condition={strPresent(details.vendorCredentialAttributes.customer)}
                />
              }
              value={details.vendorCredentialAttributes.customer || ""}
              onChange={(e) => updateCredentialsField("customer", e)}
              className={cls.field}
              error={!!errors?.["vendorCredential.customer"]}
              helperText={errors?.["vendorCredential.customer"]?.[0]}
            />
          )}
          {PROVIDERS_WITH_USERNAME.includes(details.provider) && (
            <>
              {" "}
              <TextField
                variant="standard"
                label={
                  <RequiredLabel
                    label={I18n.t("vendor_config.vendors.form.username.label")}
                    condition={strPresent(details.vendorCredentialAttributes.username)}
                  />
                }
                value={details.vendorCredentialAttributes.username || ""}
                onChange={(e) => updateCredentialsField("username", e)}
                className={cls.field}
                error={!!errors?.["vendorCredential.username"]}
                helperText={errors?.["vendorCredential.username"]?.[0]}
              />
              <TextField
                variant="standard"
                label={
                  <RequiredLabel
                    label={I18n.t("vendor_config.vendors.form.password.label")}
                    condition={strPresent(details.vendorCredentialAttributes.password)}
                  />
                }
                value={details.vendorCredentialAttributes.password || ""}
                onChange={(e) => updateCredentialsField("password", e)}
                className={cls.field}
                error={!!errors?.["vendorCredential.password"]}
                helperText={errors?.["vendorCredential.password"]?.[0]}
              />
            </>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default VendorDetailsForm;
