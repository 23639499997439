import { computed, thunk } from "easy-peasy";
import { camelizeKeys } from "humps";
import easyState from "lib/easyState";
import { pick } from "lodash";
import { strPresent } from "lib/util";
import { createStudent, saveStudent } from "services/apiStudents";
import addressesStore from "components/admin_v2/common/stores/addressesStore";
import phoneNumbersStore from "components/admin_v2/common/stores/phoneNumbersStore";

const REQUIRED = ["firstName", "lastName", "grade", "sisId", "programId"];

const studentFormStore = ({ student }) => {
  const details = {
    ...pick(student, [
      "firstName",
      "lastName",
      "schoolId",
      "grade",
      "otherNeeds",
      "specialInstructions",
      "sisId",
      "programId",
      "transportationId",
      "createdAt",
      "updatedAt"
    ]),
    dismissalGroupId: student.dismissalGroup?.id
  };
  const accommodation = pick(student, ["accommodationTypes", "accommodationNote"]);

  return {
    ...easyState("student", student),
    ...easyState("details", details),
    ...easyState("accommodation", accommodation),
    ...easyState("errors"),
    ...easyState("loading", false),
    ...easyState("addTransportation", false),
    ...easyState("startDate", null),
    ...easyState("datePickerOpen", false),

    ...addressesStore(student?.addresses),
    ...phoneNumbersStore(student?.phoneNumbers || []),

    isValid: computed((state) => {
      const { details, addTransportation, startDate } = state;
      const validDetails = REQUIRED.every((field) => strPresent(details[field]?.toString()));
      const validAddrs = state.isValidAddresses();
      const validPhones = state.isValidPhoneNumbers();
      const validTransp = !addTransportation || (addTransportation && startDate);
      return validDetails && validAddrs && validPhones && validTransp;
    }),

    formData: computed((state) => {
      const { accommodation, details } = state;
      return {
        ...details,
        ...accommodation,
        addressesAttributes: state.addressesAttributes,
        phoneNumbersAttributes: state.phoneNumbersAttributes
      };
    }),

    save: thunk(async (actions, _payload, h) => {
      const state = h.getState();
      if (!state.isValid) return Promise.reject();

      actions.setLoading(true);
      actions.setErrors(null);
      const data = state.formData;
      try {
        const st = state.student;
        if (st.id) {
          return await saveStudent(st.id, data);
        } else {
          return await createStudent(data);
        }
      } catch (err) {
        const errors = err.response?.data?.errors;
        actions.setErrors(camelizeKeys(errors));
        throw err;
      } finally {
        actions.setLoading(false);
      }
    })
  };
};

export default studentFormStore;
