import React, { Fragment, useEffect } from "react";
import cn from "classnames";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import GreenCheckbox from "components/admin_v2/ui/GreenCheckbox";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import { useStoreState, useStoreActions } from "easy-peasy";
import { strPresent, commaErrors } from "lib/util";
import useCommonPageStyles from "components/admin_v2/common/useCommonPageStyles";
import RequiredLabel from "components/admin_v2/ui/RequiredLabel";
import I18n from "utils/i18n.js";

const VendorStaffDetailsForm = ({ store }) => {
  const cls = useCommonPageStyles();
  const [state, actions] = store;
  const { details, errors } = state;
  const { vendors } = useStoreState((s) => s.vendor);
  const { updateVendors } = useStoreActions((s) => s.vendor);

  useEffect(() => {
    updateVendors();
  }, []);

  const updateField = (key, e) => {
    let value;
    if (["systemUser", "communicationAccess"].includes(key)) {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    const newDetails = { ...details, [key]: value };
    actions.setDetails(newDetails);
  };

  return (
    <Fragment>
      <Grid container spacing={5}>
        <Grid item xs={9}>
          <TextField
            variant="standard"
            label={
              <RequiredLabel
                label={I18n.t("vendor_config.staffs.form.email.label")}
                condition={strPresent(details.email)}
              />
            }
            value={details.email || ""}
            onChange={(e) => updateField("email", e)}
            className={cn("staff-email", cls.field)}
            error={!!errors?.email}
            helperText={errors?.email?.[0]}
          />
          <TextField
            variant="standard"
            label={
              <RequiredLabel
                label={I18n.t("vendor_config.staffs.form.first_name.label")}
                condition={strPresent(details.firstName)}
              />
            }
            value={details.firstName || ""}
            onChange={(e) => updateField("firstName", e)}
            className={cn("staff-firstName", cls.field)}
            error={!!errors?.firstName}
            helperText={errors?.firstName?.[0]}
          />
          <TextField
            variant="standard"
            label={
              <RequiredLabel
                label={I18n.t("vendor_config.staffs.form.last_name.label")}
                condition={strPresent(details.lastName)}
              />
            }
            value={details.lastName || ""}
            onChange={(e) => updateField("lastName", e)}
            className={cn("staff-lastName", cls.field)}
            error={!!errors?.lastName}
            helperText={errors?.lastName?.[0]}
          />
          <TextField
            variant="standard"
            label={I18n.t("vendor_config.staffs.form.staff_role.label")}
            value={details.staffRole || ""}
            onChange={(e) => updateField("staffRole", e)}
            className={cn("staff-staffRole", cls.field)}
            error={!!errors?.staffRole}
            helperText={errors?.staffRole?.[0]}
          />
          <Box>
            <FormControlLabel
              control={
                <GreenCheckbox
                  checked={details.systemUser}
                  onChange={(e) => updateField("systemUser", e)}
                />
              }
              label={I18n.t("vendor_config.staffs.form.system_user.label")}
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <GreenCheckbox
                  checked={details.communicationAccess}
                  onChange={(e) => updateField("communicationAccess", e)}
                />
              }
              label={I18n.t("school_config.staffs.form.communication_access.label")}
            />
          </Box>
          <FormControl
            className={cls.field}
            error={!!errors?.transportationVendorId}
            variant="standard"
          >
            <RequiredLabel
              label={I18n.t("vendor_config.staffs.form.vendor.label")}
              condition={!!details?.transportationVendorId}
            />
            <Select
              value={vendors.length < 1 ? "" : details?.transportationVendorId || ""}
              onChange={(e) => updateField("transportationVendorId", e)}
              className="staff-vendor"
              MenuProps={{ className: "staff-vendor-menu" }}
              error={!!errors?.transportationVendor}
            >
              {vendors.map((vendor) => (
                <MenuItem key={vendor.id} value={vendor.id}>
                  {vendor.name}
                </MenuItem>
              ))}
            </Select>
            {errors?.transportationVendor && (
              <FormHelperText>{commaErrors(errors?.transportationVendor)}</FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default VendorStaffDetailsForm;
