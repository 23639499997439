import { action, thunk } from "easy-peasy";
import { pick, isBoolean, assign } from "lodash";
import { easyStateSetters } from "lib/easyState";
import { paginationStore, enhancePaginationProps } from "lib/paginationStore";
import { fetchRouteFilters, fetchRoutes } from "services/apiRoutes";
import { setFlashMessage } from "services";

export const defaultState = {
  // options
  vehicleTypes: [],
  transportationVendors: [],
  // filters
  scope: "active",
  running: false,
  vehicleType: "",
  vendor: "",
  // status
  loading: false,
  refresh: false,
  // state
  addRouteDialogOpen: false,
  // data
  routes: []
};

export const routesStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),
  ...paginationStore(initialData),

  setFromRouter: action((state, props) => {
    const defaultProps = pick(defaultState, ["vehicleType", "scope", "vendor"]);
    if (props.refresh) props.refresh = !state.refresh;
    assign(state, defaultProps, enhancePaginationProps(props));
  }),

  fetchRoutes: thunk((actions, params, h) => {
    actions.setLoading(true);
    const stateParams = pick(h.getState(), [
      "page",
      "perPage",
      "vehicleType",
      "scope",
      "running",
      "vendor"
    ]);

    if (isBoolean(stateParams.running)) {
      stateParams.running = stateParams.running.toString();
    }
    return fetchRoutes({ ...params, ...stateParams })
      .then((r) => actions.setData(r))
      .catch((err) => setFlashMessage(err.message))
      .finally(() => actions.setLoading(false));
  }),

  fetchRouteFilters: thunk((actions, params, h) => {
    const scope = h.getState().scope;
    fetchRouteFilters({ scope }).then(({ vehicleTypes, transportationVendors }) => {
      const withUnassigned = [{ id: "unassigned", name: "Unassigned" }, ...transportationVendors];

      actions.setVehicleTypes(vehicleTypes);
      actions.setTransportationVendors(withUnassigned);
    });
  }),

  setData: action((state, data) => {
    state.routes = data.routes;
    state.total = parseInt(data.pagination.total);
  })
});
