import React from "react";
import NavTabs from "components/admin_v2/common/NavTabs";
import { pageRoutes } from "services/api";

const VendorConfigTabs = () => {
  const tabs = [
    { label: "Vendors", path: pageRoutes.vendors(), exact: false },
    { label: "Vehicles", path: pageRoutes.vehicles(), exact: false },
    { label: "Vendor Staff", path: pageRoutes.vendorStaffs(), exact: false },
    { label: "Devices", path: pageRoutes.devices(), exact: false }
  ];

  return <NavTabs tabs={tabs} />;
};

export default VendorConfigTabs;
