import React, { useEffect, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { map, intersection } from "lodash";
import ifvisible from "ifvisible.js";
import { makeStyles } from "@mui/styles";
import { format } from "date-fns-tz";
import cn from "classnames";
import { throttle } from "throttle-debounce";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useLocalStore, useStoreActions, useStoreState } from "easy-peasy";
import { parseDateFromUrl, formatDateUrl } from "lib/dates";
import { parseWithRouter, updateWithRouter, camelizeLocationSearch } from "lib/queryString";
import MultiSchoolsSelect from "components/admin_v2/ui/MultiSchoolsSelect";
import Spinner from "components/admin_v2/ui/Spinner";
import TopPageLayout from "components/admin_v2/nav/TopPageLayout";
import TripsMap from "components/admin_v2/trips/TripsMap.jsx";
import TripsTopFilters from "components/admin_v2/trips/TripsTopFilters.jsx";
import TripsBoardFilters from "components/admin_v2/trips/TripsBoardFilters.jsx";
import VendorScheduleDialog from "components/admin_v2/routes/vendor_schedules/VendorScheduleDialog.jsx";
import { tripsStore } from "components/admin_v2/trips/stores/tripsStore";
import { EnvBannerVisible } from "./nav/Envbar";
import { reorderTrips } from "./trips/tripHelpers";
import useInterval from "lib/useInterval";
import useMessageBus from "lib/useMessageBus";
import I18n from "utils/i18n.js";
import { changeFavicon, useDocumentTitle } from "lib/util";
import { getPersistedFilter } from "lib/persistedFilter";

const Trips = (props) => {
  const cls = useStyles();
  const { sortBy } = useStoreState((s) => s.avl);
  const { schools, filtersReset, isUserAdmin } = useStoreState((s) => s.app);
  const { onChangeScope, setFiltersReset, setSchool, updateSchools } = useStoreActions(
    (s) => s.app
  );
  const { date } = useStoreState((s) => s.calendar);
  const { setDate } = useStoreActions((s) => s.calendar);
  const userSchoolIds = schools.length === 1 ? map(schools, "id") : [];
  const savedCollapse =
    getPersistedFilter({
      app: "avl",
      page: "map",
      filter: "collapseAllStudents"
    }) || false;
  const store = useLocalStore(() =>
    tripsStore({
      schoolIds: userSchoolIds,
      ...camelizeLocationSearch(props),
      collapseAllStudents: savedCollapse
    })
  );
  const [state, actions] = store;

  useDocumentTitle(I18n.t("ui.avl.title"));

  useEffect(() => {
    updateSchools();
  }, []);

  useEffect(() => {
    changeFavicon("/4mativ-logo.ico");
  }, []);

  useEffect(() => {
    if (isUserAdmin || schools.length > 1) return;
    const schoolId = schools[0].id;
    const school = schools.find((s) => s.id === schoolId);

    setSchool(school);
  }, []);

  useEffect(() => {
    parseWithRouter(props, {
      date: [
        (queryDate) => {
          const newDate = parseDateFromUrl(queryDate);
          setDate(newDate);
        },
        parseDateFromUrl()
      ]
    });
    actions.setFromRouter(camelizeLocationSearch(props));
  }, [props.location.search]);

  // remove vendorId filter from query string if it's not present in the vendors list
  useEffect(() => {
    if (state.vendorOptions.find((vo) => vo.id === parseInt(state.vendorId))) return;

    updateQueryString({ vendor_id: null });
  }, [state.vendorOptions]);

  useEffect(() => {
    if (!date) {
      actions.setTrips([]);
      return;
    }

    actions.resetSelections();
    actions.fetchVendors({ date: formatDateUrl(date) });
  }, [date]);

  useEffect(() => {
    if (!date || state.schoolIds.length === 0) {
      actions.setTrips([]);
      return;
    }

    const params = { date: formatDateUrl(date) };

    actions.fetchIndividualTrips(params).catch(() => {
      actions.setLoadError(true);
      actions.setLoading(false);
    });
  }, [
    date,
    state.schoolIds,
    state.vehicleType,
    state.routeType,
    state.tripType,
    state.vendorId,
    state.addressId,
    state.studentId,
    state.routeId,
    state.status,
    state.timeZone,
    state.tripType,
    state.onlyActive,
    state.refresh
  ]);

  // remove school specific filters from search query
  useEffect(() => {
    if (filtersReset) {
      updateQueryString({ route_id: null, student_id: null, address_id: null });
      setFiltersReset(false);
    }
  }, [filtersReset]);

  useEffect(() => {
    actions.setTrips(reorderTrips(state.trips, sortBy));
  }, [sortBy]);

  const resetState = useCallback(() => {
    // reset school specific state filters
    actions.reset();
    // set to remove school specific filters from path
    setFiltersReset((r) => !r);
  }, []);

  useEffect(() => {
    onChangeScope(resetState);
    return () => onChangeScope(null);
  }, []);

  const onTripUpdates = useCallback(
    throttle(appConf.avlPollingInterval, (r) => {
      const params = { date: formatDateUrl(r.date) };

      actions.refetchTrips(params);
    }),
    []
  );

  useMessageBus({
    channel: `avl-trips-${formatDateUrl(date)}`,
    condition: !appConf.avlPollingMode && date,
    received: (r) => {
      // do not trigger refetch if received trip is out of date or school is not selected
      if (
        intersection(r.school_ids, state.schoolIds).length != 0 &&
        formatDateUrl(date) === r.date
      ) {
        onTripUpdates(r);
      }
    },
    dependencies: [state.schoolIds, date, onTripUpdates]
  });

  const handleSchoolsChange = (_e, newValues) => {
    const ids = map(newValues, "id");
    actions.setSchoolIds(ids);
    updateQueryString({ school_ids: actions.schoolIdsQueryParam(ids) });
  };

  const clearSchoolIds = () => {
    actions.setSchoolIds([]);
    actions.setVendorOptions([]);
    actions.setTrips([]);
    updateQueryString({ school_ids: null });
  };

  // polling for trips updates (fallback from ws)
  // TODO: Remove after we gain confidence on MessageBus implementation
  const pollTripsUpdates = () => {
    if (!ifvisible.now()) {
      return;
    }

    const params = { date: formatDateUrl(date) };
    actions.refetchTrips(params);
  };

  if (appConf.avlPollingMode) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useInterval(pollTripsUpdates, appConf.avlPollingInterval);
  }

  const updateQueryString = updateWithRouter(props);

  const trackingView = () => {
    if (state.trips.length === 0) {
      return (
        <Typography variant="subtitle1" className={cls.empty}>
          {state.schoolIds.length === 0
            ? I18n.t("ui.trip.select_school")
            : I18n.t("ui.trip.no_trips")}
        </Typography>
      );
    } else {
      return <TripsMap tripsStore={store} />;
    }
  };

  const closeDialog = () => {
    actions.setEditTrip(null);
  };

  const onSubmit = () => {
    closeDialog();
    actions.setRefresh(!state.refresh);
  };

  return (
    <React.Fragment>
      <TopPageLayout
        title={I18n.t("ui.avl.title")}
        filters={
          schools.length > 1 ? (
            <MultiSchoolsSelect
              schools={schools}
              schoolIds={state.schoolIds}
              onChange={handleSchoolsChange}
              withAllOption={true}
              withClearSelection={true}
              onClear={clearSchoolIds}
              rest={{ className: cls.schoolTripsFilter, limitTags: 8 }}
              disabled={state.loading}
            />
          ) : (
            <TripsTopFilters
              state={state}
              date={date}
              updateQueryString={updateQueryString}
              cls={cls}
            />
          )
        }
        fullSizeFilter={true}
        divider={false}
      />
      {schools.length > 1 && (
        <TripsTopFilters
          state={state}
          date={date}
          updateQueryString={updateQueryString}
          cls={cls}
        />
      )}
      <Box my={3}>
        <Paper className={cls.paper}>
          <Box className={cn(cls.paperTop, { [cls.afterEnvBar]: EnvBannerVisible() })}>
            {date ? (
              <TripsBoardFilters
                tripsStore={store}
                date={date}
                updateQueryString={updateQueryString}
                cls={cls}
              />
            ) : null}
          </Box>
          <Box className={cls.dataUpdated}>
            {I18n.t("ui.trip.last_update", {
              updated: format(state.lastUpdate, "MMM d, yyyy hh:mm:ss z")
            })}
          </Box>
          {state.loading ? <Spinner /> : trackingView()}
        </Paper>
      </Box>
      <VendorScheduleDialog
        open={!!state.editTrip}
        editTrip={state.editTrip}
        page="avl"
        onClose={closeDialog}
        onSuccess={onSubmit}
      />
      {/* NOTE: removed simulation form temporarily */}
      {/* <SimulateTrackingDialog
        store={store}
        cls={cls}
        open={state.simulateTrackingDialogOpen}
        toggleDialog={toggleSimulateTrackingDialog}
      /> */}
      <Dialog
        open={state.loadError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Error loading trips</DialogTitle>
        <DialogContent>
          <Stack direction="row" alignItems="center" gap={1}>
            <ErrorIcon color="error" fontSize="large"></ErrorIcon>
            <DialogContentText id="alert-dialog-description">
              An error occurred while trying to load the trips from some school. Please try again.
            </DialogContentText>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => actions.setLoadError(false)}>OK</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  paperTop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2)
  },
  stickyTop: {
    position: "sticky",
    top: "0px",
    backgroundColor: theme.custom.WHITE
  },
  afterEnvBar: {
    top: theme.spacing(5)
  },
  iconBtn: {
    height: theme.spacing(5),
    marginRight: theme.spacing(1),
    padding: theme.spacing(0)
  },
  tripsFilters: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    height: theme.spacing(5),
    "& .MuiSelect-outlined, .MuiButton-outlined, .MuiOutlinedInput-root": {
      backgroundColor: theme.custom.WHITE
    },
    "& .MuiOutlinedInput-root": {
      height: theme.spacing(5)
    }
  },
  filterItem: {
    margin: theme.spacing(0, 1)
  },
  searchInputWrapper: {
    width: theme.spacing(45)
  },
  searchField: {
    padding: `${theme.spacing(0)} !important`,
    height: theme.spacing(5)
  },
  marginLeft: {
    marginLeft: theme.spacing(5)
  },
  empty: {
    fontSize: "1.2rem",
    fontWeight: theme.custom.BOLD,
    padding: theme.spacing(4, 0),
    textAlign: "center"
  },
  schoolTripsFilter: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    width: "70%",
    backgroundColor: theme.custom.WHITE
  },
  dataUpdated: {
    float: "right",
    fontSize: "0.8rem",
    color: theme.custom.MEDIUM_GREY_2,
    marginRight: theme.spacing(2)
  }
}));

export default Trips;
